// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.combined-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.search-category-wrapper {
    display: flex;
    align-items: center;
    /* gap: 10px; */
}

.search-bar {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 0px;
    flex: 1 1;
    border-radius: 0 !important;
    font-size: 1rem !important;
    min-height: 48px !important;
}

.dropdown-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.dropdown-label {
    font-weight: bold;
}

.category-dropdown {
    padding: 8px;
    border: 0px solid #ddd;
    border-radius: 0px !important;
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    max-width: 200px;
    font-size: 1rem !important;
    min-height: 48px !important;
}

.search-button {
    padding: 12px 25px;
    border: none;
    background-color: var(--pink-dark);
    color: #fff;
    cursor: pointer;
    border-radius: 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.search-button:hover {
    opacity: .9;
}
`, "",{"version":3,"sources":["webpack://./src/CombinedComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,SAAO;IACP,2BAA2B;IAC3B,0BAA0B;IAC1B,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,6BAA6B;IAC7B,uCAAuC;IACvC,0CAA0C;IAC1C,gBAAgB;IAChB,0BAA0B;IAC1B,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,kCAAkC;IAClC,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,6BAA6B;IAC7B,gCAAgC;AACpC;;AAEA;IACI,WAAW;AACf","sourcesContent":[".combined-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.search-category-wrapper {\r\n    display: flex;\r\n    align-items: center;\r\n    /* gap: 10px; */\r\n}\r\n\r\n.search-bar {\r\n    padding: 8px;\r\n    border: 1px solid #ddd;\r\n    border-radius: 0px;\r\n    flex: 1;\r\n    border-radius: 0 !important;\r\n    font-size: 1rem !important;\r\n    min-height: 48px !important;\r\n}\r\n\r\n.dropdown-wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 5px;\r\n}\r\n\r\n.dropdown-label {\r\n    font-weight: bold;\r\n}\r\n\r\n.category-dropdown {\r\n    padding: 8px;\r\n    border: 0px solid #ddd;\r\n    border-radius: 0px !important;\r\n    border-top-left-radius: 25px !important;\r\n    border-bottom-left-radius: 25px !important;\r\n    max-width: 200px;\r\n    font-size: 1rem !important;\r\n    min-height: 48px !important;\r\n}\r\n\r\n.search-button {\r\n    padding: 12px 25px;\r\n    border: none;\r\n    background-color: var(--pink-dark);\r\n    color: #fff;\r\n    cursor: pointer;\r\n    border-radius: 0;\r\n    border-top-right-radius: 25px;\r\n    border-bottom-right-radius: 25px;\r\n}\r\n\r\n.search-button:hover {\r\n    opacity: .9;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
