import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const BrandsSection = () => {
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/brands`);
                setBrands(response.data);
            } catch (error) {
                console.error('There was an error fetching the brand data!', error);
            }
        };

        fetchBrands();
    }, []);

    return (

        <div className="row row-cols-lg-6 row-cols-md-4 row-cols-2  justify-content-center">
            {brands.map((brand) => (
                <div key={brand.id} className="col mt-3">
                    <Link to={`/products?brand=${brand.id}`}>
                        <img src={brand.image_url} className="img-fluid" alt={brand.name} />
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default BrandsSection;
