import React from 'react';
import { Link } from 'react-router-dom';
import logo from './images/logo.png';
//import './Header.css';

const Header = () => {
    return (
        <header className='sticky-top'>
                <nav className="navbar navbar-expand-lg bg-pink-dark" data-bs-theme="dark">
                    <div className="container-fluid">
                        <a className="text-white d-lg-none" href="/"><img src={logo} alt="logo" /></a>
                        <button className="navbar-toggler text-white" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav align-items-lg-center mx-auto">
                                <li className="nav-item">
                                    <a className="nav-link text-white active" aria-current="page" href="#about-us">About Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#our-products">Our Products</a>
                                </li>
                                <li className="nav-item d-lg-block d-none">
                                    <a className="nav-link text-white" href="/"><img src={logo} alt="logo" /></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#buy-now">Buy Now</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#contact-us">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
    );
};

export default Header;
