import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import banner1 from './images/banner-1.jpg';
import bannerbg from './images/white-sketch.png';
import oreobanner from './images/oreo-banner.jpg';
import chipsbanner from './images/chips-banner.jpg';
import cheetosbanner from './images/cheetos-banner.jpg';
import feastablesbanner from './images/feastables-banner.jpg';
import store1 from './images/store1.png';
import store2 from './images/store2.png';
import store3 from './images/store3.png';
import axios from 'axios';
import CombinedComponent from './CombinedComponent';
import './ProductList.css';

const ProductPage = () => {
    const [products, setProducts] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);

    const productListRef = useRef(null);
    const navigate = useNavigate(); // Hook for programmatic navigation

    useEffect(() => {
        fetchBrands();
    }, []);

    useEffect(() => {
        fetchProducts(currentPage);
    }, [selectedBrands, selectedCategory, searchTerm, currentPage]);

    const fetchProducts = async (page = 1) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/products`, {
                params: {
                    page,
                    per_page: 10,
                    brands: selectedBrands.join(','),
                    category: selectedCategory,
                    search: searchTerm
                }
            });
            const { data, current_page, last_page } = response.data;
            setProducts(data);
            setCurrentPage(current_page);
            setTotalPages(last_page);
        } catch (error) {
            console.error('There was an error fetching the products!', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchBrands = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/brands`);
            setBrands(response.data);
        } catch (error) {
            console.error('Error fetching brands:', error);
        }
    };

    const handleBrandChange = (brandId) => {
        setSelectedBrands(prevSelectedBrands => {
            const updatedSelectedBrands = prevSelectedBrands.includes(brandId)
                ? prevSelectedBrands.filter(id => id !== brandId)
                : [...prevSelectedBrands, brandId];
            return updatedSelectedBrands;
        });
        setCurrentPage(1);
    };

    const handleSelectAllBrands = () => {
        setSelectedBrands(prevSelectedBrands => {
            const allBrandIds = brands.map(brand => brand.id);
            const updatedSelectedBrands = prevSelectedBrands.length === allBrandIds.length
                ? []
                : allBrandIds;
            return updatedSelectedBrands;
        });
        setCurrentPage(1);
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setCurrentPage(1);
    };

    const handleSearchChange = (term) => {
        setSearchTerm(term);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleSearch = () => {
        fetchProducts();
        if (productListRef.current) {
            productListRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const toggleDiv = () => {
        const element = document.getElementById("SideBar");
        element.classList.toggle("show");
    };

    return (
        <>
            <div className='bg-pink-light pt-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8 mb-4'>
                            <div className="product-filters">
                                <CombinedComponent
                                    selectedCategory={selectedCategory}
                                    setSelectedCategory={handleCategoryChange}
                                    searchTerm={searchTerm}
                                    setSearchTerm={handleSearchChange}
                                    onSearch={handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-9 mb-4'>
                            <img src={banner1} className='img-fluid rounded-2' />
                        </div>
                        <div className='col-md-3 mb-4'>
                            <div className='row align-content-between h-100'>
                                <div className='col-md-12 col-6'>
                                    <img src={oreobanner} className='img-fluid rounded-2' />
                                </div>
                                <div className='col-md-12 col-6'>
                                    <img src={chipsbanner} className='img-fluid rounded-2' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 mb-4'>
                            <img src={cheetosbanner} className='img-fluid rounded-2' />
                        </div>
                        <div className='col-md-6 mb-4'>
                            <img src={feastablesbanner} className='img-fluid rounded-2' />
                        </div>
                    </div>
                </div>
                <div><img src={bannerbg} className='w-100 mt-5' /></div>
            </div>
            <div className='container pt-4'>
                <div className="product-list-page row gx-md-5">
                    <div className='sidebar col-lg-2 col-md-3 pt-0 position-relative' id="SideBar">
                        <h3 className='mb-4 filter-label' onClick={toggleDiv}><i className="bi bi-funnel"></i> FILTER </h3>
                        <ul className='filter-list'>
                            <li className="checkbox-container">
                                <input
                                    type="checkbox"
                                    checked={selectedBrands.length === brands.length && brands.length > 0}
                                    onChange={handleSelectAllBrands}
                                />
                                <span className="custom-checkbox"></span>
                                <span className="checkbox-label">All</span>
                            </li>
                            {brands.map((brand) => (
                                <li key={brand.id} className="checkbox-container">
                                    <input
                                        type="checkbox"
                                        onChange={() => handleBrandChange(brand.id)}
                                        checked={selectedBrands.includes(brand.id)}
                                    />
                                    <span className="custom-checkbox"></span>
                                    <span className="checkbox-label">{brand.name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="main-content col-lg-10 col-md-9 pt-0">
                        <h3 className='mb-4'>PRODUCTS LIST</h3>
                        <div className="product-list row row-cols-lg-4 row-cols-md-3 row-cols-2" ref={productListRef}>
                            {loading ? (
                                <p>Loading...</p>
                            ) : products.length > 0 ? (
                                products.map(product => (
                                    <div className='col mb-3' key={product.id}>
                                        <div className="product-card">
                                            <img src={product.image_url} alt={product.product_name} />
                                            <h6 className='small my-2'>{product.product_name}</h6>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No products found.</p>
                            )}
                        </div>
                        <div className="pagination">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1} className='me-2'
                            >
                                <i className="bi bi-chevron-left"></i>
                            </button>
                            <span> Page {currentPage} of {totalPages} </span>
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages} className='ms-2'
                            >
                                <i className="bi bi-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <section className="about-banner bg-white">
                <div className="container position-relative">
                    <div className="row py-5">
                        <div className="col-lg-7 mt-5">
                            <div className="row">
                                <div className="col-6">
                                    <img src={store1} className="img-fluid rounded-5" />
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-start flex-column h-100">
                                        <div className="mb-auto">
                                            <img src={store2} className="img-fluid rounded-5" />
                                        </div>
                                        <div className="mt-auto">
                                            <img src={store3} className="img-fluid rounded-5" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 mt-5 text-md-start text-center">
                            <h3 className="bg-white text-green-light rounded-pill d-inline-block px-4 py-3 mt-lg-5 mb-3 fw-semibold">
                                Singapore's Ultimate Snacking Paradise!</h3>
                            <p className="text-white">Craving a sweet escape or a savory fix? Look no further than munch maniac, the perfect hangout spot for snacking enthusiasts of all ages. We boast one of the largest selections of imported American and UK candies, snacks, and drinks in Singapore. <br /><br />
                                From trending treats like Feastables by Mr. Beast and Prime Hydration by KSI & Logan Paul to nostalgic favorites like Nerds Candy and limited-edition Oreo flavors, we've got it all. Can't find that TikTok or Instagram sensation you're craving? Just ask, and we'll do our best to source it for you! We offer unbeatable deals on popular brands and our friendly staff is always ready to help you find your perfect snack. <br /><br />
                                Located at 87 Marine Parade Central, #01-503B/C, Singapore 440087, munch maniac isn't just a store – it's a vibrant candy lover's dream come true. <br /><br />
                                Come indulge your sweet tooth and discover why we're the sweetest spot in town!</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProductPage;
