import React from 'react';
//import './Footer.css';

const Footer = () => {
    return (
        <footer className="bg-black py-4">
                    <div className="container">
                        <div className="row justify-content-between text-center">
                            <div className="col-md-auto">
                                <p className="mb-0 text-white small">© 2024 munch maniac</p>
                            </div>
                        </div>
                    </div>
        </footer>
    );
};

export default Footer;
