import React from 'react';
import { Link } from 'react-router-dom';
import logo from './images/logo.png';
//import './Header.css';

const ProductsPageHeader = () => {
    return (
        <header className='sticky-top'>
                <nav className="navbar navbar-expand-lg bg-pink-dark" data-bs-theme="dark">
                    <div className="container-fluid">
                        <a className="text-white d-lg-none" href="/"><img src={logo} alt="logo" /></a>
                        
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav align-items-lg-center mx-auto">
                                <li className="nav-item d-lg-block d-none">
                                    <a className="nav-link text-white" href="/"><img src={logo} alt="logo" /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
    );
};

export default ProductsPageHeader;
