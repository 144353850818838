import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './CombinedComponent.css'; // Import the CSS file for styling

const CombinedComponent = ({ selectedCategory, setSelectedCategory, searchTerm, setSearchTerm, onSearch }) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`https://devadminmunchmaniaclaravel.nityo.in/api/categories`);
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, []);

    return (
        <div className="combined-container">
            <div className="search-category-wrapper">
                
                <div className="dropdown-wrapper">
                    
                    <select
                        id="category-dropdown"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        className="category-dropdown form-select form-select-lg"
                    >
                        <option value="">All Categories</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search products..."
                    className="search-bar form-control form-control-lg"
                />
                <button onClick={onSearch} className="search-button">Search</button>
            </div>
        </div>
    );
};

export default CombinedComponent;
