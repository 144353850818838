import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './css/custom.css';
import bannerproducts from './images/banner-products.png';
import bannermobileproducts from './images/mobile-banner-product.png';
import profile1 from './images/harsh-singhania.jpg';
import profile2 from './images/JVR.png';
import store1 from './images/store1.png';
import store2 from './images/store2.png';
import store3 from './images/store3.png';
import product2 from './images/product2.png';
import shopee from './images/partner/shopee.png';
import lazada from './images/partner/lazada.png';
import deliveroo from './images/partner/deliveroo.png';
import grabfood from './images/partner/grabfood.png';
import grain from './images/partner/grain.png';
import whyq from './images/partner/whyq.png';
import fongfu from './images/partner/fongfu.png';
import foodpanda from './images/partner/foodpanda.png';
import barcode from './images/munch-maniac-whatsapp-barcode.png';
import BrandsSection from './BrandsSection'; // Import BrandsSection component

const HomePage = () => {
    return (
        <div className="App">
            <section className="main-banner pt-5 pb-md-5 pb-0">
                <div className="container text-center">
                    <div className="row justify-content-center position-relative">
                        <div className="white-spring-top1 end-0 me-md-0 me-4 d-lg-none"></div>
                        <div className="white-spring-bottom1 mt-5 start-0 ms-4 d-lg-none"></div>
                        <div className="col-lg-10 col-8 mx-auto pb-lg-0 pb-4">
                            <picture>
                                <source srcSet={bannermobileproducts} media="(orientation: portrait)" />
                                <img src={bannerproducts} className="img-fluid" alt="banner" />
                            </picture>
                        </div>
                    </div>
                    <div className="banner-headings">
                        <h2 className="fw-semibold text-white mb-0">Welcome to <span className="logo-font">munch maniac</span></h2>
                        <h2 className="fw-semibold text-white">Your Ultimate Snacking Destination</h2>
                    </div>
                    <h6 className="fw-semibold bg-white rounded-pill text-pink-dark py-2 px-4 d-inline-block">Where we celebrate the
                        joy of snacking! <br />
                        Step into our world of delicious treats and irresistible munchies</h6>
                </div>
            </section>

            <section className="about-banner py-5" id='about-us'>
                <div className="container position-relative">
                    <div className="white-spring-top1 ms-5 d-lg-block d-none"></div>
                    <div className="white-spring-bottom1 mt-5 start-25"></div>
                    <div className="white-spring-triangle mt-5 end-0 d-lg-block d-none"></div>
                    <div className="row position-relative z-index-2 mt-lg-5">
                        <div className="col-lg-6 text-white mt-5 mb-md-5 text-lg-start text-center">
                            <h3 className="bg-white text-green-light rounded-pill d-inline-block px-4 py-3 mt-lg-5 mb-3 fw-semibold">
                                About munch maniac</h3>
                            <p>Welcome to The munch mania Store, where we turn snacking into an
                                adventure! Our mission is to provide a one-of-a-kind shopping
                                experience for all snack enthusiasts. We bring the world to you through
                                our curated selection of international snacks, candies, and drinks.</p>
                            <p>
                                But that’s not all! Our stores are designed to transport you to different
                                worlds, whether it’s exploring galaxies or immersing yourself in the world
                                of superheroes. There is no better way to make someone happy than
                                with a delicious snack and a memorable experience. Our team is
                                dedicated to providing exceptional service and catering to all your
                                snacking needs.
                            </p>
                            <p className="mb-md-5">
                                So come down to the munch mania Store and join us on a journey
                                through the world of snacks. We promise it will be an adventure you
                                won’t forget!
                            </p>
                        </div>
                        <div className="col-lg-6 text-white mt-lg-5 text-lg-start text-center">
                            <div className="position-relative px-lg-0 px-5">
                                <div className="white-spring-top1 start-0 ms-4 d-lg-none"></div>
                                <div className="white-spring-triangle end-0 me-4 d-lg-none"></div>
                                <img src={product2} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-md-5 meet-behind-munch">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9 col-12 text-center">
                            <h3 className="bg-pink-light text-white rounded-pill d-block px-4 py-3 mt-5 mb-3 fw-semibold">Meet the
                                Men <span className='d-sm-inline d-block'>behind munch maniac</span> </h3>
                        </div>
                        <div className="col-lg-9 col-12 pb-5">
                            <div className="row align-items-center">
                                <div className="col-md-4 col-8 mx-auto mt-5">
                                    <img src={profile1} className="img-fluid rounded-5" />
                                </div>
                                <div className="col-md-8 mt-md-5 mt-4 text-md-start text-center">
                                    <h3 className="text-pink-light fw-semibold">Harsh Singhania</h3>
                                    <p className="small">Who doesn’t like snacks? Growing up in Singapore, Harsh has friends from all taste buds of the world. Singaporean friends, Malay friends, European friends and American friends who introduced him to the different flavours the world has to offer. munch maniac is a passion project started by Harsh and his friends to bring the unheard of and untasted snacks from United States and such to Singapore. Along with popular American Snacks, we also brought along snacks popularised by famous youtubers, such as Feastables by Mr. Beast, PRIME Hydration by KSI & Logan Paul, JOYRIDE by Ryan Trahan, PIZZAFY! sauce by Airrack and Alani Energy Drinks by Katie Hearn.</p>
                                    <p className='small'>Harsh Singhania is a foodie by nature, and nothing brings him more joy than being able to give the good people of Singapore some amazing new candies, snacks and drinks.</p>
                                </div>
                            </div>
                            <div className="row align-items-center mb-md-4">
                                <div className="col-md-4 col-8 mx-auto mt-5">
                                    <img src={profile2} className="img-fluid rounded-5" />
                                </div>
                                <div className="col-md-8 mt-md-5 mt-4 text-md-start text-center">
                                    <h3 className="text-pink-light fw-semibold">Julian Veloso Rosales</h3>
                                    <p className="small">Growing up, some of my fondest memories were snacking on treats from all
                                        around
                                        the world. I had this privilege because my father traveled a lot for work and would
                                        usually bring back something new for the family to try. <br /><br />
                                        The novelty of these flavors brought me joy over the years and cultivated my curious
                                        palate. When the opportunity arose to join Harsh in providing uncommon foreign
                                        snacks and beverages to our local community, it was a no-brainer to join in.<br /><br />
                                        I look forward to bringing you more interesting munchies at reasonable prices. :)</p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>

            <section className="about-store pb-5">
                <div className="container position-relative">
                    <div className="white-spring-top1 end-0 me-md-0 me-5 mt-3"></div>
                    <div className="white-spring-bottom1 mt-5 end-25"></div>

                    <div className="row py-5">
                        <div className="col-lg-7 mt-5">
                            <div className="row">
                                <div className="col-6">
                                    <img src={store1} className="img-fluid rounded-5" />
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-start flex-column h-100">
                                        <div className="mb-auto">
                                            <img src={store2} className="img-fluid rounded-5" />
                                        </div>
                                        <div className="mt-auto">
                                            <img src={store3} className="img-fluid rounded-5" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 mt-5 text-md-start text-center">
                            <h3 className="bg-white text-pink-light rounded-pill d-inline-block px-4 py-3 mt-lg-5 mb-3 fw-semibold">
                            Singapore's Ultimate Snacking Paradise!</h3>
                            <p className="text-white mb-md-5">Craving a sweet escape or a savory fix? Look no further than munch maniac, the perfect hangout spot for snacking enthusiasts of all ages. We boast one of the largest selections of imported American and UK candies, snacks, and drinks in Singapore. <br /><br />
                            From trending treats like Feastables by Mr. Beast and Prime Hydration by KSI & Logan Paul to nostalgic favorites like Nerds Candy and limited-edition Oreo flavors, we've got it all. Can't find that TikTok or Instagram sensation you're craving? Just ask, and we'll do our best to source it for you! We offer unbeatable deals on popular brands and our friendly staff is always ready to help you find your perfect snack. <br /><br />
                            Located at 87 Marine Parade Central, #01-503B/C, Singapore 440087, munch maniac isn't just a store – it's a vibrant candy lover's dream come true. <br /><br />
                            Come indulge your sweet tooth and discover why we're the sweetest spot in town!</p>
                        </div>
                    </div>
                </div>
            </section>

            
                
            <section className="our-products py-5" id="our-products">
                <div className="container">
                    <div className="text-center">
                        <h3 className="bg-pink-light text-white rounded-pill d-inline-block px-5 py-3 mt-md-5 mb-3 fw-semibold mx-auto">
                            Our Products
                        </h3>
                        <p className="fw-medium text-black">Indulge your cravings with our wide range of delectable snacks! From savory to sweet, crunchy to chewy, we've got something for every palate. Explore our diverse selection of snacks, carefully curated to satisfy even the most discerning snack connoisseurs.</p>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12 col-9 mx-auto">                            
                                <BrandsSection />
                        </div>
                    </div>
                </div>
            </section>

            <section className="our-partners py-5" id="buy-now">
                <div className="container py-lg-5">
                    <div className="px-md-0 px-5">
                        <h6 className="text-center text-white">Ready to satisfy your cravings? Purchase your favorite snacks <br />
                            conveniently from our trusted partners. Click below to shop now:</h6>
                        <div className="row justify-content-center flex-xl-nowrap align-items-center text-center row-cols-lg-auto row-cols-md-4 row-cols-2">
                            <div className="col mt-4">
                                <a href="https://sg.shp.ee/JjLifQc" target='_blank'>
                                    <img src={shopee} className="img-fluid" />
                                </a>
                            </div>
                            <div className="col mt-4">
                                <a href="https://s.lazada.sg/s.10rfi" target='_blank'>
                                    <img src={lazada} className="img-fluid" />
                                </a>
                            </div>
                            <div className="col mt-4">
                                <img src={deliveroo} className="img-fluid" />
                            </div>
                            <div className="col mt-4">
                                <img src={grabfood} className="img-fluid" />
                            </div>
                            <div className="col mt-4">
                                <img src={grain} className="img-fluid" />
                            </div>
                            <div className="col mt-4">
                                <img src={whyq} className="img-fluid" />
                            </div>
                            <div className="col  mt-4">
                                <img src={fongfu} className="img-fluid" />
                            </div>
                            <div className="col  mt-4">
                                <img src={foodpanda} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-us py-5 position-relative" id="contact-us">
                    <div className="pink-spring-top1 mt-md-5 ms-5"></div>
                    <div className="pink-spring-bottom1 mt-5 ms-5 end-0 me-5 bottom-0 mb-md-5"></div>
                    <div className="container-xxl px-lg-5 mb-md-5 position-relative z-index-2">
                        <div className="px-md-0 px-4">
                            <div className="text-center">
                                <h3 className="bg-pink-light text-white rounded-pill d-inline-block px-5 py-3 mt-5 mb-3 fw-semibold">Contact
                                    Us</h3>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg col-auto text-black text-center mt-4">
                                    <h6>Have a question or just want to say hello?<br />
                                        Reach out to us!<br />
                                        Our friendly team is here to assist you.</h6>
                                    <div className="p-2 shadow-lg bg-white rounded-4 overflow-hidden mt-4 location-map">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7874915368907!2d103.90552369999999!3d1.3024578999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19fa46fcdd5b%3A0x57c542ca0eb63003!2sMunch%20Maniac%20Pte.%20Ltd.!5e0!3m2!1sen!2sin!4v1720762335639!5m2!1sen!2sin" width="600" height="250" loading="lazy" title="Google Maps"></iframe>
                                    </div>
                                </div>
                                <div class="col-auto mt-4 d-lg-block d-none"><div class="border-right-pink h-100 mx-2">&nbsp;</div></div>                                
                                <div className="col-lg text-black text-center mt-4">
                                    <h6>Have a question? </h6>
                                    <div className="circle-36 bg-pink-light mx-auto my-2">
                                        <i className="bi bi-envelope-fill text-white"></i>
                                    </div>
                                    <a className="bg-pink-light text-white rounded-pill d-block px-4 py-2 fw-semibold email-address h5 text-decoration-none" href="mailto:sales@munchmaniac.com">sales@munchmaniac.com</a>
                                    <div className="circle-36 bg-pink-light mx-auto my-2">
                                        <i className="bi bi-telephone-fill text-white"></i>
                                    </div>
                                    <a className="bg-pink-light text-white rounded-pill d-block px-4 py-2 fw-semibold email-address h5 text-decoration-none contact-no" href="tel:+6589501367">+65 8950 1367</a>
                                </div>

                                <div class="col-auto mt-4 d-lg-block d-none"><div class="border-right-pink h-100 mx-2">&nbsp;</div></div>

                                <div className="col-lg-4 col-auto mt-4">
                                    <div className='row'>
                                        <div className='col-lg-6 text-center'>
                                            <h6>Connect with us on</h6>
                                            <h3 className='fw-bold'>WHATSAPP</h3>
                                        </div>
                                    </div>
                                    <div className='row justify-content-center'>
                                        <div className='col-lg-6'>
                                            <img src={barcode} className="img-fluid" />
                                        </div>
                                        <div className='col-lg-6 text-start px-lg-0 mt-lg-0 mt-5 col-auto'>
                                            <h5 className='fw-bold'>Once you connect</h5>
                                            <ul className='small p-0 ms-4'>
                                                <li>Reserve product/orders</li>
                                                <li>Be the 1st to know new arrivals</li>
                                                <li>Exclusive Promotions</li>
                                                <li>Invite to Events</li>
                                                <li>And more...</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
    );
};

export default HomePage;