import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePageHeader from './HomePageHeader'; // Header for HomePage
import ProductPageHeader from './ProductsPageHeader'; // Header for ProductPage
import Footer from './Footer';
import ProductPage from './ProductsPage'; // Import the ProductPage component
import HomePage from './HomePage'; // Example other component

const Header = () => {
    const location = useLocation();

    // Check the current path and return the appropriate header
    if (location.pathname === "/products") {
        return <ProductPageHeader />;
    } else {
        return <HomePageHeader />;
    }
};

const App = () => {
    return (
        <Router>
            <div className="app">
                <Header /> {/* Conditionally render the header */}
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/products" element={<ProductPage />} />
                    {/* Add other routes as needed */}
                </Routes>
                <Footer />
            </div>
        </Router>
    );
};

export default App;
